import React from 'react';

import { PAGES } from '../utils/utils';
import Equipe from '../components/fr/equipe';
import Layout from '../components/layout';
import SEO from '../components/seo';

const EquipePage = () => (
  <Layout page={PAGES.EQUIPE}>
    <SEO title="Équipe" />
    <Equipe />
  </Layout>
);

export default EquipePage;
