import React, { useEffect, useState } from 'react';

import { getYearsSinceOfficeBirth } from '../../utils/utils';

const Equipe = () => {
  const [isJqueryInitiated, setIsJqueryInitiated] = useState(false);

  function initiateJquerySliders() {
    ['CL', 'JPL', 'MHL', 'PML'].forEach((initials) => {
      $(`.btn${initials}`).click(function () {
        /* slide */
        $(`div#${initials}Bio`).slideToggle();
        /* get sign */
        var isDisplayed = document.getElementById(`sign${initials}`).innerHTML;
        /* change sign */
        document.getElementById(`sign${initials}`).innerHTML =
          isDisplayed == '+' ? '-' : '+';
      });
    });
  }

  useEffect(() => {
    if (!isJqueryInitiated) {
      initiateJquerySliders();
      setIsJqueryInitiated(true);
    }
  });

  return (
    <>
      <div id="slogan" className="effect1">
        <h1>Notre Équipe</h1>
      </div>

      <div id="img-wrap">
        <img src="/img/lemire-equipe-large.jpg" alt="Équipe" title="Équipe" />
      </div>

      <div id="info">
        <div id="equipe">
          {/* 1er avocat */}
          <div itemScope itemType="https://schema.org/Person">
            <ul className="equipeListe">
              <li>
                <img
                  src="/img/claude_lemire.jpg"
                  itemProp="image"
                  className="btnCL"
                  height="185"
                  width="130"
                  alt="Claude Lemire"
                />
              </li>

              <ul>
                <h2>
                  <span itemProp="name">
                    <span className="accent5">Claude Lemire</span>
                  </span>
                </h2>
              </ul>

              <li>
                <div className="equipeTitre">
                  <h3>
                    <span className="accent2">Titre</span>
                  </h3>
                  <p>
                    <span itemProp="jobTitle">Associé</span>
                  </p>
                </div>
              </li>

              <li>
                <div className="equipeTel">
                  <ul className="equipeData">
                    <li>
                      <h3>
                        <span className="accent2">Téléphone</span>
                      </h3>
                      <p>
                        <a href="tel:+14509661055,1">
                          <span itemProp="telephone">(450) 966-1055</span> poste
                          1
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <div className="equipeMail">
                  <ul>
                    <li>
                      <h3>
                        <span className="accent2">Adresse électronique</span>
                      </h3>
                      <p>
                        <a
                          href="mailto:clemire@lemireavocat.com"
                          itemProp="email"
                        >
                          clemire@lemireavocat.com
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <a id="equipe" className="scroll btnCL">
              Biographie <span id="signCL">+</span>
            </a>
            <div id="CLBio">
              <div
                itemProp="description"
                align="justify"
                className="equipeDesc"
              >
                <p>
                  M<sup>e</sup> Claude Lemire est membre du Barreau du Québec
                  depuis 1975. Sa pratique se concentre principalement en
                  litige, en droit familial, immobilier et civil.
                </p>
                <p>
                  Au cours des {getYearsSinceOfficeBirth()} années dédiées à la
                  pratique du droit à titre d'avocat plaideur en cabinet privé,
                  il a été amené à plaider devant différents tribunaux dans des
                  litiges de nature civile, administrative et pénale.
                </p>
                <p>
                  Tout au cours de sa carrière, M<sup>e</sup> Claude Lemire a
                  été très actif au Barreau du Québec ayant notamment été
                  bâtonnier de la section Laurentides-Lanaudière. Il est
                  également membre du Comité de révision des plaintes du bureau
                  du syndic du Barreau du Québec.
                </p>
              </div>
            </div>
            <hr />
          </div>

          {/* 2e avocat */}
          <div itemScope itemType="https://schema.org/Person">
            <ul className="equipeListe">
              <li>
                <img
                  src="/img/jean-philippe_lemire.jpg"
                  itemProp="image"
                  className="btnJPL"
                  height="185"
                  width="130"
                  alt="Jean-Philippe Lemire"
                />
              </li>
              <ul>
                <h2>
                  <span itemProp="name">
                    <span className="accent5">Jean-Philippe Lemire</span>
                  </span>
                </h2>
              </ul>
              <li>
                <div className="equipeTitre">
                  <h3>
                    <span className="accent2">Titre</span>
                  </h3>
                  <p>
                    <span itemProp="jobTitle">Associé</span>
                  </p>
                </div>
              </li>
              <li>
                <div className="equipeTel">
                  <ul className="equipeData">
                    <li>
                      <h3>
                        <span className="accent2">Téléphone</span>
                      </h3>
                      <p>
                        <a href="tel:+14509661055,3">
                          <span itemProp="telephone">(450) 966-1055</span> poste
                          3
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="equipeMail">
                  <ul>
                    <li>
                      <h3>
                        <span className="accent2">Adresse électronique</span>
                      </h3>
                      <p>
                        <a
                          href="mailto:jplemire@lemireavocat.com"
                          itemProp="email"
                        >
                          jplemire@lemireavocat.com
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <a id="equipe" className="scroll btnJPL">
              Biographie <span id="signJPL">+</span>
            </a>
            <div id="JPLBio">
              <div
                itemProp="description"
                align="justify"
                className="equipeDesc"
              >
                <p>
                  La pratique de Jean-Philippe Lemire englobe divers aspects du
                  droit dans les domaines de la construction, immobilier,
                  assurances et responsabilité civile. Il est également appelé à
                  rédiger des avis juridiques sur des sujets variés.
                </p>
                <p>
                  Jean-Philippe Lemire a œuvré dans différents dossiers de
                  litige, notamment en matière de diffamation, vices cachés,
                  vices de construction, inexécutions contractuelles et de
                  dommages corporels.
                </p>
                <p>
                  Il s'est joint au cabinet en 2009 à titre d'avocat après y
                  avoir œuvré à titre d'étudiant et stagiaire.
                </p>
              </div>
            </div>
            <hr />
          </div>

          {/* 3e avocate */}
          <div itemScope itemType="https://schema.org/Person">
            <ul className="equipeListe">
              <li>
                <img
                  src="/img/marie-helene_lemire.jpg"
                  itemProp="image"
                  className="btnMHL"
                  height="185"
                  width="130"
                  alt="Marie-Hélène Lemire"
                />
              </li>
              <ul>
                <h2>
                  <span itemProp="name">
                    <span className="accent5">Marie-Hélène Lemire</span>
                  </span>
                </h2>
              </ul>
              <li>
                <div className="equipeTitre">
                  <h3>
                    <span className="accent2">Titre</span>
                  </h3>
                  <p>
                    <span itemProp="jobTitle">Associée</span>
                  </p>
                </div>
              </li>
              <li>
                <div className="equipeTel">
                  <ul className="equipeData">
                    <li>
                      <h3>
                        <span className="accent2">Téléphone</span>
                      </h3>
                      <p>
                        <a href="tel:+14509661055">
                          <span itemProp="telephone">(450) 966-1055</span>
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="equipeMail">
                  <ul>
                    <li>
                      <h3>
                        <span className="accent2">Adresse électronique</span>
                      </h3>
                      <p>
                        <a
                          href="mailto:mhlemire@lemireavocat.com"
                          itemProp="email"
                        >
                          mhlemire@lemireavocat.com
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <a id="equipe" className="scroll btnMHL">
              Biographie <span id="signMHL">+</span>
            </a>
            <div id="MHLBio">
              <div
                itemProp="description"
                align="justify"
                className="equipeDesc"
              >
                <p>
                  En 2005, Marie-Hélène Lemire se joint à M<sup>e</sup> Claude
                  Lemire et fonde 4 ans plus tard le cabinet Lemire Lemire
                  avocats s.e.n.c.r.l.
                </p>
                <p>
                  Sa pratique englobe tous les aspects du litige familial, civil
                  et commercial.
                </p>
                <p>
                  Marie-Hélène Lemire a œuvré dans différents dossiers de
                  litige, notamment en matière de garde d'enfants, divorce,
                  partage de la résidence familiale, enrichissement injustifié,
                  inexécutions contractuelles et dommages corporels.
                </p>
                <p>Elle offre également des services de médiation familiale.</p>
              </div>
            </div>
            <hr />
          </div>

          {/* 4e avocat */}
          <div itemScope itemType="https://schema.org/Person">
            <ul className="equipeListe">
              <li>
                <img
                  src="/img/pierre-marc_lemire.jpg"
                  itemProp="image"
                  className="btnPML"
                  height="185"
                  width="130"
                  alt="Pierre-Marc Lemire"
                />
              </li>
              <ul>
                <h2>
                  <span itemProp="name">
                    <span className="accent5">Pierre-Marc Lemire</span>
                  </span>
                </h2>
              </ul>
              <li>
                <div className="equipeTitre">
                  <h3>
                    <span className="accent2">Titre</span>
                  </h3>
                  <p>
                    <span itemProp="jobTitle">Associé</span>
                  </p>
                </div>
              </li>
              <li>
                <div className="equipeTel">
                  <ul className="equipeData">
                    <li>
                      <h3>
                        <span className="accent2">Téléphone</span>
                      </h3>
                      <p>
                        <a href="tel:+14509661055,2">
                          <span itemProp="telephone">(450) 966-1055</span> poste
                          2
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="equipeMail">
                  <ul>
                    <li>
                      <h3>
                        <span className="accent2">Adresse électronique</span>
                      </h3>
                      <p>
                        <a
                          href="mailto:pmlemire@lemireavocat.com"
                          itemProp="email"
                        >
                          pmlemire@lemireavocat.com
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <a id="equipe" className="scroll btnPML">
              Biographie <span id="signPML">+</span>
            </a>
            <div id="PMLBio">
              <div
                itemProp="description"
                align="justify"
                className="equipeDesc"
              >
                <p>
                  Pierre-Marc Lemire s'est joint au cabinet en 2019 après avoir
                  œuvré pendant plus de 10 ans à Montréal, notamment dans un
                  cabinet d'envergure nationale.
                </p>
                <p>
                  M<sup>e</sup> Lemire exerce dans les domaines du litige
                  commercial et des services financiers. Sa pratique englobe
                  tous les aspects du droit bancaire, de la faillite et de
                  l'insolvabilité, de la restructuration et du droit de la
                  construction.
                </p>
                <p>
                  Il agit également en matière de louage commercial, conflits
                  entre actionnaires et droit successoral.
                </p>
                <p>
                  Son expertise en ces matières l'a amené à représenter
                  plusieurs institutions financières, syndics, créanciers et
                  débiteurs dans divers mandats de mise en place de facilités de
                  crédit, de prise de sûretés, de réalisation de sûretés, de
                  mises sous séquestre, de saisies avant jugement et
                  responsabilité civile, de même qu'à effectuer des
                  représentations devant les tribunaux du Québec.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Equipe.propTypes = {};

export default Equipe;
